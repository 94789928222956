const columns = [
    {
        title: '拆佣状态',
        dataIndex: 'process_form[拆佣状态].value',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: '项目名称',
        dataIndex: 'process_form[项目名称].value',
        scopedSlots: { customRender: 'name' }
    },
    {
        title: '成交房号',
        dataIndex: 'process_form[成交房号].value',
        scopedSlots: { customRender: 'room' }
    },
    {
        title: '购房/承租方',
        dataIndex: 'process_form[购房/承租方].value',
        scopedSlots: { customRender: 'client' }
    },
    {
        title: '认购时间',
        dataIndex: 'process_form[认购时间].value',
        scopedSlots: { customRender: 'order' }
    },
    {
        title: '签约日期',
        dataIndex: 'process_form[签约日期].value',
        scopedSlots: { customRender: 'sign' }
    },
    {
        title: '业绩归属日期',
        dataIndex: 'process_form[业绩归属日期].value',
        scopedSlots: { customRender: 'deal' }
    },
    {
        title: '公司佣金',
        dataIndex: 'process_form[公司佣金].value',
        scopedSlots: { customRender: 'company_commission' }
    },
    {
        title: '对外拆佣',
        dataIndex: 'process_form[对外拆佣].value',
        scopedSlots: { customRender: 'distribute_commission' }
    },
    {
        title: '公司净佣',
        dataIndex: 'process_form[公司净佣].value',
        scopedSlots: { customRender: 'company_net_commission' }
    },
    {
        title: '已拆佣金额',
        dataIndex: 'process_form[已拆佣金额].value',
        scopedSlots: { customRender: 'total_distributed' }
    }
]
export const dismantleColumns = [
    ...columns,
    {
        title: '财务补录',
        dataIndex: 'dismantle',
        scopedSlots: { customRender: 'dismantle' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' }
    }
]

export const operationColumns = [
    ...columns,
    {
        title: '操作',
        dataIndex: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' }
    }
]

export const typeMap = {
    'main_seller': {
        status: 'success',
        text: '成交人'
    },
    'side_seller': {
        status: 'processing',
        text: '分单人'
    }
}

export const statusMap = {
    '未完成': {
        status: 'processing',
        color: '#87d068',
        text: '未完成拆佣'
    },
    '已完成拆佣': {
        status: 'success',
        color: '#108ee9',
        text: '已完成拆佣'
    }
}
